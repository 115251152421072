body{
  background: url('images/back.jpg');
  background-size: cover;
  color: white;
}

h1{
  font-size: 3rem;
  font-weight: 700;
}

p,a{
  font-size: 1.2rem;
  opacity: 0.8;
}

.App {
  line-height: 100vh;
  height: 100%;
  text-align: center;
}

.App .centered{
  line-height: 1.4;
  display: inline-block;
  vertical-align: middle;
}

.contact{
  width: 100%;
  height: 100%;
  margin-top:60px;
}

.contact a{
  opacity: 1;
  margin-right:20px;
  text-decoration: none;
  color: white;
}

.contact a::after{
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: white;
  margin-top: 5px;
  margin-left:10px;
}

.contact .last::after{
  opacity: 0 !important;
}

.contact .last{
  cursor: pointer;
}

.devs-rect{
  height: 30px;
  left: 50%;
  transform: translate(-50%);
  margin-top:5px;
  position: absolute;
  clip: rect(0px,1000px,30px,0px);
}

.animated-div{
  position: relative;
  animation-fill-mode: both !important;
  top: -30px;
}

@keyframes FromTop{
  from {top:-30px;}
  to {top:0;}
}

@keyframes FromBottom{
  from {top:0px;}
  to {top:-30px;}
}